import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./upload.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home";
import NoPage from "./Pages/NoPage";
import Login from "./Pages/Login";
import Users from "./Pages/Users";
import Settings from "./Pages/Settings";
import Pages from "./Pages/Pages";
import Page from "./Pages/Page";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/page/:name" element={<Page />} />
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Users />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/Pages" element={<Pages />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
