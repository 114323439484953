import { useCallback } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import UserService from "../Services/UserService";
import { message } from "antd";
import useConfig from "../Hooks/useConfig";
const Layout = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const config = useConfig();
  const navigate = useNavigate();
  const ErrorMessage = useCallback(() => {
    messageApi.open({
      key: "logout",
      type: "error",
      content: "Error!",
      duration: 2,
    });
  }, [messageApi]);
  const successMessage = useCallback(() => {
    messageApi.open({
      key: "logout",
      type: "success",
      content: "تم!",
      duration: 2,
    });
  }, [messageApi]);
  const endCall = () => {
    successMessage();
  };
  const logout = async () => {
    try {
      messageApi.open({
        key: "logout",
        type: "loading",
        content: "جار التحميل...",
        duration: 0,
      });
      await UserService.logout(config);
      navigate("/Login", { replace: true });
      endCall();
    } catch (error) {
      // Handle error
      ErrorMessage();
    }
  };
  return (
    <div className="sb-nav-fixed">
      {contextHolder}
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <Link to="/" className="nav-link text-white">
          Logo
        </Link>

        <button
          className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
          id="sidebarToggle"
          onClick={(event) => {
            event.preventDefault();
            document.body.classList.toggle("sb-sidenav-toggled");
          }}
        >
          <i className="bi bi-list mobile-nav-toggle"></i>
        </button>
      </nav>
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
              <div className="nav">
                <Link to="/" className="nav-link ">
                  المستخدمين
                </Link>
                <Link to="/Settings" className="nav-link ">
                  الإعدادات
                </Link>
                <Link to="/Pages" className="nav-link ">
                  الصفحات
                </Link>
                <div onClick={logout} style={{ cursor: "pointer" }} className="nav-link ">
                  تسجيل خروج
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div id="layoutSidenav_content">
          <main className="d-flex flex-fill">
            <Outlet />
          </main>
          <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
              <div className="d-flex align-items-center justify-content-between small">
                <div className="text-muted">Copyright &copy; dragon tech 2022</div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Layout;
