import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, message } from "antd";
import { AxiosError } from "axios";
import { CheckOutlined, DeleteOutlined, EditOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import { User } from "../type";
import useColors from "../Hooks/useColors";
import useConfig from "../Hooks/useConfig";
import UserService from "../Services/UserService";
import ReactPaginate from "react-paginate";
import SettingsService, { settings } from "../Services/SettingsService";
const key = "Loading";
const Settings: React.FC = () => {
  const [, Colors] = useColors();
  const navigate = useNavigate();
  const [gamefee, setgamefee] = useState<string>("");
  const [coin, setcoin] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);
  const config = useConfig();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    navigate("/Login", { replace: true });
  };

  const ErrorMessage = () => {
    messageApi.open({
      key,
      type: "error",
      content: "Error!",
      duration: 2,
    });
  };
  const successMessage = () => {
    messageApi.open({
      key,
      type: "success",
      content: "تم!",
      duration: 2,
    });
  };
  const endCall = () => {
    setLoading(false);
    successMessage();
  };
  const store = async () => {
    try {
      setLoading(true);
      const data: settings[] = [
        { name: "gamefee", value: gamefee },
        { name: "coin", value: coin },
      ];
      await SettingsService.Store(data, config);
      endCall();
    } catch (error) {
      // Handle error
      const err = error as { message: string; status: number };
      if (err.status === 422) {
        messageApi.open({
          key,
          type: "error",
          content: err.message,
          duration: 2,
        });
      } else ErrorMessage();
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        messageApi.open({
          key,
          type: "loading",
          content: "جار التحميل...",
          duration: 0,
        });
        const result = await SettingsService.get(config);
        setcoin(result.coin);
        setgamefee(result.gamefee);
        successMessage();
      } catch (error) {
        // Handle error
        ErrorMessage();

        const err = error as AxiosError<{ error: string }>;
        if (err.response?.status === 401) {
          logout();
        }
      }
    };
    fetchData();
  }, []);
  return (
    <div className="w-100">
      <div className="container p-3 pt-4">{contextHolder}</div>
      <div className="form w-50 mx-auto">
        <div className="form-floating mb-3 ">
          <input
            type="number"
            className="form-control"
            value={gamefee}
            onChange={(e) => {
              setgamefee(e.target.value);
            }}
          />
          <label htmlFor="floatingInput">خصم اللعب</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="number"
            className="form-control"
            value={coin}
            onChange={(e) => {
              setcoin(e.target.value);
            }}
          />
          <label htmlFor="floatingInput">العملات عند التسجيل</label>
        </div>
        <Button key="submit" type="primary" loading={loading} onClick={store}>
          حفظ
        </Button>
      </div>
    </div>
  );
};

export default Settings;
